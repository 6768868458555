import NewsArticle from "./newsArticle";

export default class NewsItemInfo {
  id?: string;
  itemId: string;
  long?:string;
  short?:string;

  symbol?: string;
  articles?: NewsArticle[];
  isArticlesUpdated?: boolean;
  createdOn?: Date;
  modifiedOn?: Date;
  refreshedOnL?: number;


  constructor(id: string, itemId: string) {
    this.id = id;
    this.itemId = itemId;
  }

  static create(id: string, data: any): NewsItemInfo {
    const newsItem = new NewsItemInfo(id, data.itemId || '');
    Object.assign(newsItem, {
      long: data.long,
      short: data.short,
      symbol: data.symbol,
      articles: data.articles,
      isArticlesUpdated: data.isArticlesUpdated,
      createdOn: data.createdOn,
      modifiedOn: data.modifiedOn,
      refreshedOnL: data.refreshedOnL
    });
    return newsItem;
  }
}
