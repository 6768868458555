import React from 'react';
import FormattedDate from './formattedDate';

const HeaderTop = () => {
  return (
    <div className="w-full text-center text-gray-900 font-serif antialiased">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex flex-col items-center border-t border-gray-200">
          <div className="text-5xl tracking-tight font-extrabold opacity-60 py-1 pt-4 w-auto">
            Traded News
          </div>
          <div className="text-sm text-gray-400 border-t border-b border-gray-100 w-[400px]">
            <FormattedDate date={new Date().toISOString()} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
