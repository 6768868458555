'use client';

import React from 'react';

interface Props {
  date: string;
}

const FormattedDate: React.FC<Props> = ({ date}) => {
  const d = new Date(date);
  const locale = window.navigator.language;
  return (
    <div>
      {new Intl.DateTimeFormat(locale, {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      }).format(d)}
    </div>
  );
};

export default FormattedDate;
