import {
  addDoc,
  collection,
  doc,
  getDoc,
  DocumentReference, getDocs,
  getFirestore, limit,
  onSnapshot,
  query, QueryDocumentSnapshot,
  Unsubscribe, where, documentId,
  setDoc,
  updateDoc,
  arrayUnion
} from "@firebase/firestore";

import {app, db} from "../services/firebase.config";
import ListNews from "../entities/listNews";
import NewsItemInfo from "../entities/newsItemInfo";
import ItemInfo from "@/entities/itemInfo";
import { it } from "node:test";

export function useFirebaseService() {
  const COLL_NEWS = "news";
  const COLL_ITEMS = "items";
  const COLL_LISTS = "lists";
  const COLL_VISITORS = "visitors";
  const BATCH_SIZE = 10;

  function docSnapshotToDataWithId(docSnap: QueryDocumentSnapshot) {
    return {
      id: docSnap.id,
      ...docSnap.data()
    }
  }

  function querySnapshotToDataWithId(qSnapshot: any) {
    return qSnapshot.docs.map((doc: any) => docSnapshotToDataWithId(doc));
  }

  const getItemDoc = (id: string) => {
    if(!id) return null;
    return doc(db, COLL_ITEMS, id);
  }

  const getNewsDoc = async (symbol: string) => {
    const docs = await getDocumentsByField(COLL_NEWS, "symbol", symbol);
    return docs.length > 0 ? docs[0] : null;
  }

  const getDocumentsByField = async (collectionName: string, field: string, value: any): Promise<any[]> => {
    let qry = query(collection(db, collectionName), where(field, "==", value));
    const docs = (await getDocs(qry)).docs;
    return docs.map(doc => doc.data());
  };

  const getNewsItemSnapshot = (symbol: string, handleData: (doc: any) => void, handleError: (error: any) => void): Unsubscribe => {
    if (!symbol) return () => null;

    const docRef = doc(db, COLL_NEWS, symbol);

    return onSnapshot(docRef, {
      next: (docSnapshot) => {
        handleData(docSnapshot.data());
      },
      error: (error) => {
        console.log("==========================================");
        console.log(`Error onSnapshot`);
        console.log("==========================================");
        handleError(error);
      }
    });
  }

  const getItemsSnapshot = (items:string[], handleData: (doc: any) => void, handleError: (error: any) => void): Unsubscribe => {
    return getMultipleDocuments(items, COLL_ITEMS, handleData, handleError);
  }

  const getListItem = async (id: string): Promise<any> => {
    if (!id) {
      throw new Error("Invalid ID");
    }
    const docRef = doc(db, COLL_LISTS, id);

    try {
      let docSnap = await getDoc(docRef);
      return docSnap.exists() ? docSnap.data() : null;
    } catch (error) {
      console.log(`Error getting document: ${error}`);
      return null;
    }
  }

  const addNews = async (): Promise<DocumentReference | null> => {
    try {
      const docRef = await addDoc(collection(db, COLL_NEWS), {
        name: "Tokyo",
        country: "Japan"
      });

      console.log("Document written with ID: ", docRef.id);
      return docRef;

    } catch (error) {
      console.error("Error adding document: ", error);
      return null;
    }
  }

  const getMultipleDocuments = (
    ids: string[],
    collectionName: string,
    onData: (data: any[]) => void,
    onError: (error: Error) => void
  ): Unsubscribe => {
    if (!ids || ids.length === 0) {
      onError(new Error('No IDs provided'));
      return () => {};
    }

    const collectionRef = collection(db, collectionName);

    if (ids.length <= BATCH_SIZE) {
      const docQuery = query(collectionRef, where(documentId(), 'in', ids));

      return onSnapshot(
        docQuery,
        {
          next: (snapshot) => onData(querySnapshotToDataWithId(snapshot)),
          error: onError
        }
      );
    } else {
      const batches:any[] = [];
      for (let i = 0; i < ids.length; i += BATCH_SIZE) {
        const batchIds = ids.slice(i, i + BATCH_SIZE);
        batches.push(query(collectionRef, where('id', 'in', batchIds)));
      }

      const unsubscribes = batches.map((batchQuery, index) =>
        onSnapshot(
          batchQuery,
          {
            next: (snapshot) => {
              if (index === batches.length - 1) {
                Promise.all(batches.map(async (b) => {
                  const snap = await onSnapshot(b, () => {});
                  return querySnapshotToDataWithId(snap);
                }))
                  .then((results) => {
                    const flatResults = results.flat();
                    onData(flatResults);
                  })
                  .catch(onError);
              }
            },
            error: onError
          }
        )
      );

      return () => unsubscribes.forEach(unsubscribe => unsubscribe());
    }
  };

  const updateVisitorCount = async (ip: string): Promise<void> => {
    const today = new Date().toISOString().split('T')[0]; 
    const visitorRef = doc(db, COLL_VISITORS, today);

    try {
      const docSnap = await getDoc(visitorRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        if (!data.ips.includes(ip)) {
          await updateDoc(visitorRef, {
            count: data.count + 1,
            ips: arrayUnion(ip)
          });
        }
      } else {
        await setDoc(visitorRef, {
          date: today,
          count: 1,
          ips: [ip]
        });
      }
    } catch (error) {
      console.error("Error updating visitor count:", error);
    }
  };

  const getVisitorCountSnapshot = (
    onData: (count: number) => void,
    onError: (error: Error) => void
  ): Unsubscribe => {
    const today = new Date().toISOString().split('T')[0]; 
    const visitorRef = doc(db, COLL_VISITORS, today);

    return onSnapshot(
      visitorRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          onData(data.count || 0);
        } else {
          onData(0);
        }
      },
      onError
    );
  };

  async function getListData(listId: string): Promise<any> {
    const listRef = doc(db, COLL_LISTS, listId);
    const listDoc = await getDoc(listRef);
    return listDoc.data() || {};
  }

  async function getListNewsInfo(listId: string): Promise<ListNews> {
    const defaultList = [createDefaultNewsItem()];

    try {
      // Step 1: Get list data
      const listData = await getListData(listId);
      const itemIds = listData.items || [];
      const listName = listData.name || "";

      // If no items in list, return early with default
      if (!itemIds.length) {
        return new ListNews(listId, listName, defaultList);
      }

      // Step 2: Get item information for all items
      const itemInfos: ItemInfo[] = [];
      try {
        const itemsSnapshot = await getDocs(
          query(collection(db, COLL_ITEMS), 
          where(documentId(), 'in', itemIds))
        );
        
        itemsSnapshot.forEach(doc => {
          const data = doc.data();
          if (data && data.symbol) {  // Only add items with valid symbols
            itemInfos.push(data as ItemInfo);
          }
        });
      } catch (error) {
        console.error('Error fetching item information:', error);
        return new ListNews(listId, listName, defaultList);
      }

      // If no valid items found, return early with default
      if (!itemInfos.length) {
        return new ListNews(listId, listName, defaultList);
      }

      // Step 3: Extract and filter valid symbols
      const symbols = itemInfos
        .map(item => item.symbol)
        .filter((symbol): symbol is string => !!symbol);

      if (!symbols.length) {
        return new ListNews(listId, listName, defaultList);
      }

      // Step 4: Get news items for all symbols
      let newsItems: NewsItemInfo[] = [];
      try {
        const newsSnapshot = await getDocs(
          query(collection(db, COLL_NEWS), 
          where(documentId(), 'in', symbols))
        );

        newsItems = newsSnapshot.docs
          .map(doc => NewsItemInfo.create(doc.id, doc.data()))
          .filter(item => item.long || item.short); // Only include items with content
      } catch (error) {
        console.error('Error fetching news items:', error);
        return new ListNews(listId, listName, defaultList);
      }

      // Return with default list if no valid news items found
      return new ListNews(
        listId, 
        listName, 
        newsItems.length ? newsItems : defaultList
      );

    } catch (error) {
      console.error('Error in getListNewsInfo:', error);
      return new ListNews(listId, "", []);
    }
  }

  const getMultipleListsNewsInfo = async (listIds: string[]): Promise<ListNews[]> => {
    const results: ListNews[] = [];
    for (const listId of listIds) {
      const listNews = await getListNewsInfo(listId);
      results.push(listNews);
    }
    return results;
  }

  function createDefaultNewsItem(itemId: string = 'default', symbol: string = ''): NewsItemInfo {
    const now = new Date();
    const newsItem = new NewsItemInfo(itemId, itemId);
    newsItem.symbol = symbol;
    newsItem.long = `No News as of ${now.toLocaleString()}`;
    newsItem.short = `No News as of ${now.toLocaleString()}`;
    newsItem.articles = [];
    newsItem.isArticlesUpdated = false;
    newsItem.createdOn = now;
    newsItem.modifiedOn = now;
    newsItem.refreshedOnL = now.getTime();
    return newsItem;
  }

  const cleanup = async () => {
    // Add any cleanup logic here if needed
    // This is a no-op for now since we're using one-time fetches
    return Promise.resolve();
  }

  return {
    getItemDoc,
    getNewsDoc,
    getNewsItemSnapshot,
    getItemsSnapshot,
    getListItem,
    addNews,
    getMultipleDocuments,
    updateVisitorCount,
    getVisitorCountSnapshot,
    getListNewsInfo,
    getMultipleListsNewsInfo,
    getListData,
    cleanup
  }
}
