import { useEffect, useState } from 'react';

interface ProgressIndicatorProps {
  isLoading: boolean;
  loadingProgress?: number;
  overlayClassName?: string;
  className?: string;
}

export default function ProgressIndicator({ 
  isLoading, 
  loadingProgress, 
  overlayClassName = "",
  className = ""
}: ProgressIndicatorProps) {
  const [indeterminatePosition, setIndeterminatePosition] = useState(0);

  useEffect(() => {
    if (!isLoading || loadingProgress !== undefined) return;

    // Animate indeterminate progress
    const interval = setInterval(() => {
      setIndeterminatePosition(prev => (prev + 2) % 200); // 200% to account for the full animation
    }, 20);

    return () => clearInterval(interval);
  }, [isLoading, loadingProgress]);

  if (!isLoading) return null;

  const defaultOverlayClasses = "absolute inset-0 bg-gray-50/80 backdrop-blur-sm z-10 flex items-center justify-center";
  const defaultProgressClasses = "w-full max-w-[200px] h-1 bg-gray-200 rounded-full overflow-hidden";

  return (
    <div className={`${defaultOverlayClasses} ${overlayClassName}`}>
      <div className={`${defaultProgressClasses} ${className}`}>
        {loadingProgress !== undefined ? (
          // Determinate progress bar
          <div 
            className="h-full bg-blue-500 rounded-full transition-all duration-300 ease-out"
            style={{ width: `${loadingProgress}%` }}
          />
        ) : (
          // Indeterminate progress bar
          <div 
            className="h-full bg-blue-500 rounded-full transition-all duration-300 ease-out relative"
            style={{ 
              width: '30%',
              transform: `translateX(${indeterminatePosition}%)`,
              animation: 'indeterminate 2s infinite linear'
            }}
          />
        )}
      </div>
      <style jsx>{`
        @keyframes indeterminate {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(200%);
          }
        }
      `}</style>
    </div>
  );
}
