import LRUCache from "lru-cache";
export default class CachingService {
  private static instance: CachingService;
  private cache: LRUCache<string, any>;
  private constructor() {
    this.cache = new LRUCache<string, any>(
      {
        max: 1000,
        maxSize:5000,
        ttl: 1000 * 60 * 60 * 3 /* 3 hours */,
        allowStale: false,
        updateAgeOnGet: false,
        updateAgeOnHas: false,
        sizeCalculation: (value: any) => {
          return 1;
        }
    });
  }
  public static getCache(): CachingService {
    if (!CachingService.instance) {
      CachingService.instance = new CachingService();
    }
    return CachingService.instance;
  }
  public get(key: string): any {
    return this.cache.get(key);
  }
  public set(key: string, value: any): void {
    this.cache.set(key, value);
  }
}
