import NewsItemInfo from "./newsItemInfo";

export default class ListNews {
  listId: string;
  listName: string;
  newsItems: NewsItemInfo[];

  constructor(listId: string, listName: string, newsItems: NewsItemInfo[] = []) {
    this.listId = listId;
    this.listName = listName;
    this.newsItems = newsItems;
  }
}
