import React from 'react';
import dynamic from 'next/dynamic';
import HeaderTop from './header-top';

interface HeaderDayProps {
  listIds: {
    id: string;
    caption: string;
  }[];
}

// Dynamically import components that might use browser APIs
const HeaderDayComponent = dynamic<HeaderDayProps>(() => import('./header-day'), { 
  ssr: false,
  loading: () => (
    <div className="flex justify-between text-sm text-gray-400 px-4 py-2">
      Loading...
    </div>
  )
});

interface HeaderContainerProps {
  listIds: {
    id: string;
    caption: string;
  }[];
}

const HeaderContainer = ({ listIds }: HeaderContainerProps) => {
  return (
    <header className="sticky top-0 border-b border-gray-200 z-20 w-full">
      <div className="font-serif bg-gray-50">
        <HeaderTop />
        <HeaderDayComponent listIds={listIds} />
      </div>
    </header>
  );
};

export default HeaderContainer;
