import SecRssFeedItem from "@/entities/secRssFeedItem";

export default class RssAggregatorService {

  async getRssFeed() : Promise<SecRssFeedItem[]> {
    let retVal: SecRssFeedItem[] = [];

    const url = "https://www.sec.gov/news/pressreleases.rss"
    const parseString = require('xml2js').parseString;

    await fetch(url)
      .then(res => res.text())
      .then(async data => {
        await parseString(data, (err: any, result: any) => {
          if (err) {
            console.error(err);
          } else {
            const feed = result.rss.channel[0];
            // console.log(feed.title[0]);

            feed.item.map((item: any) => (retVal.push({
              title: item.title[0],
              link: item.link[0],
              description: item.description[0],
              pubDate: item.pubDate[0]
            })));
            // console.log(items);
          }
        });
      })
      .catch(err => {
        console.error(err);
      });

    console.log(`Returning ${retVal.length} items`);

    return retVal;
  }

  static create() {
    return new RssAggregatorService();
  }
}
